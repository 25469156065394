/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState, useEffect } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import gsap from 'gsap';
import { useRouter } from 'next/router';
import Logo from '@/components/Icon/Logo';
import { useGSAP } from '@gsap/react';
import Title from '@/components/Title';
import Marquee from 'react-fast-marquee';
import BScroll from '@better-scroll/core';
import useIsMobile from '@/hooks/useIsMobile';
import LeadButton from '@/components/LeadButton';
import Button from '@/components/Button';
import TPLink from '@/components/TPLink';

const iconEmoji = [
  {
    img: '/images/home/banner-emoji-1.png',
    id: 1,
  },
  {
    img: '/images/home/banner-emoji-2.png',
    id: 2,
  },
  {
    img: '/images/home/banner-emoji-3.png',
    id: 3,
  },
];

function Banner() {
  const container = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const iconRef = useRef<HTMLImageElement>(null);
  const iconIndexRef = useRef(0); // 用于追踪当前图标的索引
  const divRef = useRef<HTMLDivElement>(null);
  const [scrollObj, setscrollObj] = useState<any>();
  const router = useRouter();
  const isMobile = useIsMobile();

  //   // h1文字和视频 同时渐显 持续1s
  //   // 剩余元素 渐显  持续1s （ fadeClass opacity-0 ）
  //   // 评价自动滚动
  //   // 视频滚动
  useGSAP(
    () => {
      const icon = iconRef.current;

      const updateSrc = () => {
        if (iconRef.current) {
          iconEmoji.forEach((item, index) => {
            const icon = iconRef?.current?.children[index] as HTMLDivElement;
            if (index === iconIndexRef.current) {
              icon.style.display = 'block';
            } else {
              icon.style.display = 'none';
            }
          });
        }
      };

      // emoji切换动画
      const animateIcon = () => {
        gsap.to(icon, {
          duration: 0.6, // 缩放动画持续时间
          opacity: 1,
          scale: 1, // 放大至原始大小
          x: 0, // 初始x坐标
          y: 0, // 初始y坐标
          ease: 'power4.out', // 缓动函数
          onComplete: () => {
            gsap.to(icon, {
              duration: 0.8, // 展示图片时间延长
              onComplete: () => {
                gsap.to(icon, {
                  duration: 0.5, // 缩小动画持续时间
                  opacity: 0,
                  scale: 0, // 缩小到0
                  x: -40, // 回到原始x坐标
                  y: -80, // 回到原始y坐标
                  ease: 'power4.in', // 缓动函数，可以根据需要调整
                  onComplete: () => {
                    // 切换到下一个图标
                    iconIndexRef.current = (iconIndexRef.current + 1) % iconEmoji.length;
                    updateSrc();
                    animateIcon(); // 递归调用，实现循环动画
                  },
                });
              },
            });
          },
        });
      };

      // const animateFlag = () => {
      //   const ulElement = ulRef.current;
      //   if (!ulElement) return;

      //   const liElements = ulElement.querySelectorAll('li');
      //   // offsetWidth 是否存在
      //   const totalWidth = Array.from(liElements).reduce((sum, li) => sum + (li ? li.offsetWidth : 0), 0);

      //   // 复制
      //   liElements.forEach((li) => {
      //     ulElement.appendChild(li.cloneNode(true));
      //   });

      //   // 初始化时间线动画
      //   const timeline = gsap.timeline({
      //     repeat: -1,
      //     onComplete: () => {
      //       gsap.set(ulElement, { x: 0 });
      //       // 在此函数退出之前直接删除克隆的元素，以避免删除两次元素
      //       liElements.forEach((li) => {
      //         if (li.parentNode === ulElement) {
      //           ulElement.removeChild(li);
      //         }
      //       });
      //     },
      //   });
      //   timeline.to(ulElement, { x: -totalWidth, duration: totalWidth / 50, ease: 'linear' });
      // };

      // 封装 .h1Title 的动画
      const animateH1Title = (timeline: gsap.core.Timeline) => {
        return timeline.to(
          '.h1Title',
          {
            opacity: 1,
            duration: 1,
            // onComplete: () => {
            //   animateFlag();
            // },
          },
          0,
        );
      };

      // 封装 .fadeClass 的动画
      const animateFadeClass = (timeline: gsap.core.Timeline) => {
        return timeline.to(
          '.fadeClass',
          {
            opacity: 1,
            duration: 0.8,
          },
          0.4,
        );
      };

      // 封装 .iconEmoji 的动画
      const animateIconEmoji = (timeline: gsap.core.Timeline) => {
        return timeline.fromTo(
          '.iconEmoji',
          {
            opacity: 0,
            scale: 0,
            x: -40,
            y: -80,
          },
          {
            duration: 0.5,
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            ease: 'power4.out',
            onComplete: () => {
              animateIcon();
            },
          },
          1.3,
        );
      };

      // 封装视频的动画
      const animateVideos = (timeline: gsap.core.Timeline) => {
        const videoOptions = {
          scale: 1,
          transformOrigin: '0% 0%',
          duration: 0.5,
          ease: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
        };
        timeline.fromTo('.video1', { scale: 0, transformOrigin: '0% 0%' }, videoOptions, 0);
        timeline.fromTo('.video2', { scale: 0, transformOrigin: '0% 0%' }, videoOptions, 0);
        return timeline;
      };

      if (isMobile) {
        const mobileTimeline = gsap.timeline();
        animateH1Title(mobileTimeline);
        animateFadeClass(mobileTimeline);
        animateIconEmoji(mobileTimeline);
      } else {
        const desktopTimeline = gsap.timeline();
        animateH1Title(desktopTimeline);
        animateVideos(desktopTimeline);
        animateFadeClass(desktopTimeline);
        animateIconEmoji(desktopTimeline);
      }
    },
    { scope: container },
  );

  const { t } = useTranslation(['home', 'path']);
  const classBtnData: {
    btnName: string;
    href: string;
    ga: string;
    btnType: 'chinese' | 'english' | 'math';
  }[] = [
    {
      btnName: t('banner-中文'),
      btnType: 'chinese',
      href: '/chinese/',
      ga: 'ChineseClick',
    },
    {
      btnName: t('banner-数学'),
      btnType: 'math',
      href: '/math/',
      ga: 'MathClick',
    },
    {
      btnName: t('banner-英文'),
      btnType: 'english',
      href: '/english/',
      ga: 'EnglishClick',
    },
  ];

  const initBScroll = () => {
    setscrollObj(
      new BScroll(divRef.current as HTMLDivElement, {
        //  可以使用原生的点击
        click: true,
        scrollX: true,
        scrollbar: false,
      }),
    );
  };

  useEffect(() => {
    if (divRef.current) {
      initBScroll();
    }
    return () => {
      scrollObj?.destroy();
    };
  }, [divRef.current]);

  const classHtml = () => {
    return (
      <div className="fadeClass fixScroll opacity-0">
        <div>
          <div className=" text-center lg:text-left" ref={divRef}>
            <div className="mt-1.5  inline-block space-x-4  whitespace-nowrap px-5 pb-5 md:overflow-visible lg:mt-4 lg:px-0 lg:pb-0 xl:mt-6">
              {classBtnData?.map((item) => (
                <Button
                  key={item.btnType}
                  theme="empty"
                  traceName={`O_Home_FirstModule_${item.ga}`}
                  className={cls(
                    ' shrink-0 whitespace-nowrap border-2 border-white bg-[linear-gradient(90deg,rgba(255,83,83,0.12)_8.55%,rgba(109,56,251,0.12)_61.14%,rgba(0,157,255,0.12)_100%),linear-gradient(135deg,rgba(255,255,255,0.56)_0%,#FFF_100%)] px-4 py-2 text-sm text-wk-words-1 [&_strong]:font-medium',
                  )}
                  onClick={() => {
                    router.push(item.href);
                  }}
                >
                  <strong>{item.btnName}</strong>
                </Button>
              ))}
            </div>
          </div>
        </div>

        <div className="px-5 lg:mt-[50px] lg:px-0 xl:mt-[70px]">
          <div className=" text-center lg:text-left">
            <LeadButton
              theme="colorfulBorder"
              size="xl"
              className=""
              traceName="O_Home_FirstModule_CTAClick"
              traceGa4Name="new_entry_HomePage_FirstModuleCTA"
            >
              {t('banner-按钮')}
            </LeadButton>
          </div>

          <TPLink>
            <p className="mt-[18px] flex items-center justify-center text-xs lg:mt-5  lg:justify-start lg:text-sm xl:text-xl">
              <span className="mr-2">Trustpilot</span>
              <span className="h-[14px] w-[81px] lg:h-4 lg:w-[92px] xl:h-[20px] xl:w-[115px]">
                <Image alt={t('tp-alt')} width={115} height={20} src="/images/home/userReviews-tp2.png" quality={100} />
              </span>
              <span className="ml-2" dangerouslySetInnerHTML={{ __html: t('banner-tp-1') }} />
              {/* <span className="px-1 text-wk-words-3 lg:px-1.5">|</span>
            <span className="" dangerouslySetInnerHTML={{ __html: t('banner-tp-2') }} /> */}
            </p>
          </TPLink>
        </div>
      </div>
    );
  };

  return (
    <section ref={container} className="relative z-10" data-expose-event="O_Home_FirstModuleExpose">
      {/* 内容 */}
      <div className="container relative mt-[124px] flex flex-col justify-between lg:mt-[196px]  lg:flex-row lg:items-end  lg:pl-[90px] lg:pr-14 xl:mt-[223px]  xl:pl-[140px] xl:pr-25">
        <div className="h1Title relative flex-1 opacity-0 ">
          <Title
            className=" relative z-20 lg:text-left [&]:lg:text-3xl [&]:lg:leading-normal [&]:xl:text-[42px] [&]:xl:leading-normal"
            innerHTML
            theme="colorful"
            htmlLabel="h1"
          >
            {`${t('banner-标题')}
          <span class="block lg:inline-block relative z-[-1] -top-1.5 lg:top-0">
            <span class=" lg:absolute lg:flex lg:h-[66px] xl:h-[88px] xl:top-[-75px] xl:-left-8 lg:-left-6 lg:-top-[58px]">
              <span class="hidden lg:h-[66px]  lg:w-[66px] lg:block  xl:h-[88px] xl:w-[88px]">
                <Image
                  src="/images/home/banner-avatar.png"
                  alt="WuKong"
                  class="inline-block"
                  width={88}
                  height={88}
                />
              </span>
              <span class=" relative z-10 self-end">
                <span class=" inline-block rounded-[1px_10px_10px_10px] border border-white bg-[linear-gradient(135deg,#ffffff,rgba(255,255,255,0.5))] py-0.5 pl-3 pr-2.5 text-base font-bold leading-normal shadow-[0px_4px_9px_rgba(0,0,0,0.08)] xl:-ml-3 lg:-ml-2  lg:mb-2 lg:text-sm lg:leading-normal">
                  <span class="bg-[linear-gradient(180deg,#FF5353_-10%,#6D38FB_22.68%,#009DFF_92.5%)] bg-clip-text text-transparent  whitespace-nowrap">
                  ${t('banner-年龄')}
                  </span>
                </span>
              </span>
            </span>
          </span>
          `}
          </Title>
          <div className="hidden lg:block">{classHtml()}</div>
        </div>
        {/* 视频 */}
        <div className="relative mx-auto  mt-4 h-[260px] w-[335px]  lg:mt-0 lg:h-[323px] lg:w-[400px] xl:h-[418px] xl:w-[520px]">
          <div className=" relative h-56  w-[300px]  rounded-xl   lg:h-[278px] lg:w-[370px] lg:rounded-[27px] xl:h-[360px] xl:w-[480px] xl:rounded-5xl">
            <video
              className="video1  block h-full w-full  rounded-xl   bg-wk-border-3  object-cover lg:rounded-[27px] xl:rounded-5xl"
              src="https://cdnwukong.com/images/growth/video/home-banner-video-1.mp4"
              controlsList="nodownload, nofullscreen"
              playsInline
              x5-video-player-fullscreen="false"
              x5-playsinline="true"
              webkit-playsinline="true"
              loop
              autoPlay
              muted
              contextMenu="return false"
              disablePictureInPicture
              poster="/images/home/poster-2.png"
            />
            <Logo className="fadeClass absolute left-5 top-3.5 h-auto w-16 text-white opacity-0  md:left-5  lg:left-[26px]  lg:top-[18px] lg:w-[76px]  xl:left-8 xl:top-[26px] xl:w-[100px]" />
          </div>
          <div className="video2  absolute bottom-0 right-0  box-border  h-[111px] w-[148px] rounded-2xl border-[6px] border-white bg-white  lg:h-[137px]  lg:w-[182px] lg:rounded-[18px] lg:border-8 xl:h-[178px]  xl:w-[237px] xl:rounded-3xl xl:border-[10px]">
            <video
              className="block h-full w-full   rounded-xl   bg-wk-border-3   object-cover xl:rounded-2xl"
              src="https://cdnwukong.com/images/growth/video/home-banner-video-2.mp4"
              controlsList="nodownload, nofullscreen"
              playsInline
              x5-video-player-fullscreen="false"
              x5-playsinline="true"
              webkit-playsinline="true"
              loop
              autoPlay
              muted
              contextMenu="return false"
              disablePictureInPicture
              poster="/images/home/poster-3.png"
            />
          </div>
          <div
            ref={iconRef}
            className="absolute -right-0.5  -top-12 h-[74px] w-[86px] lg:-right-6 lg:-top-16 lg:h-[88px] lg:w-[114px] xl:right-[-74px] xl:top-[-75px] xl:h-[138px] xl:w-47"
          >
            {iconEmoji.map(({ img }, index) => (
              <img
                key={img}
                className={cls('iconEmoji scale-0 opacity-0', index === 0 ? 'block' : 'hidden')}
                src={img}
                width={138}
                height={188}
                alt="WuKong"
              />
            ))}
          </div>
          <div className="fadeClass absolute bottom-4 left-3 z-10  w-40  overflow-hidden rounded-full bg-white py-2 opacity-0  shadow-wk2 after:absolute after:left-0 after:top-0 after:z-40 after:block after:h-full  after:w-full  after:rounded-full after:bg-[linear-gradient(90deg,#ffffff,rgba(255,255,255,0.00)_40%,_rgba(255,255,255,0.00)_60%,#ffffff)] lg:bottom-5  lg:left-4 lg:w-47 lg:py-3 xl:bottom-7 xl:left-5  xl:w-[244px] xl:py-4">
            <Marquee direction="left">
              <ul ref={ulRef} className=" flex h-[30px] flex-row lg:h-[23px] xl:h-[30px] ">
                {Array(8)
                  .fill(0)
                  .map((_, index) => {
                    const country = `/images/home/banner-flag-${index + 1}.png`;
                    return (
                      <li
                        key={country}
                        className=" mx-1.5 h-[30px] w-10 shrink-0 lg:mx-1 lg:h-[23px] lg:w-[30px]  xl:mx-1.5 xl:h-[30px] xl:w-10 "
                      >
                        <Image className="inline-block" src={country} width={40} height={30} alt="WuKong" />
                      </li>
                    );
                  })}
              </ul>
            </Marquee>
          </div>
        </div>
      </div>

      <div className="lg:hidden">{classHtml()}</div>
    </section>
  );
}

export default Banner;
